import stencilUtils from '../../common/utils/stencilUtils'
import handleStencilSearchInfo from '../../common/utils/handleStencilSearchInfo'
import BasePage from '../../common/BasePage'

import listenOptionChange, { offOptionChangeHook } from './listenOptionChange'

import bottomCartTemplate from './bottomCart.html'
import shoppingListTemplate from './shoppingList.html'
import tbodyTemplate from './tbody.html'
import searchResultContainerTemplate from './searchResultContainer.html'
import searchResultItemTemplate from './searchResultItem.html'
import editOptionsModalTemplate from './editOptionsModal.html'
import skuSearchResultTemplate from './skuSearchResult.html'
import renameModalTemplate from './renameModal.html'
import {
  setRectangle,
  swatch,
  date,
  inputCheckbox,
  inputFile,
  inputNumbers,
  inputText,
  productList,
  textarea,
  setSelect,
  setRadio,
  isAllRequiredOptionFilled,
  modifierParentIdSeparate,
} from '../../components'
import currencyCodeFormat from '../../hbs/helpers/currencyCodeFormat'
import { DEFAULT_IMAGE_URL } from '../../hbs/helpers/getImage'

export default class ShoppingList extends BasePage {
  constructor() {
    super()
    this.name = 'ShoppingList'
    this.state = {
      defaultCurrency: {},
      isOpen: false,
      cart: {},
      statusSelectOptions: [],
      statusTitle: [],
      products: [],
      productTotalQty: 0,
      pagination: {
        offset: 0,
        limit: 20,
        totalCount: 0,
      },
      customerInfo: {
        firstName: '',
        lastName: '',
      },
      description: '',
      isOwner: '',
      name: '',
      status: '',
      gTierPrice: {},
      isAppendStyle: false,
      shopingListItemId: '',
      editOptionsModal: new window.B3Modal.modal({
        stickyFooter: true,
        closeMethods: ['overlay', 'escape'],
        closeLabel: this.text['global.form.close'],
        onClose: () => {
          this.offOptionChangeHook()
        },
      }),
      renameModal: new window.B3Modal.modal({
        stickyFooter: true,
        closeMethods: ['overlay', 'escape'],
        closeLabel: this.text['global.form.close'],
      }),
      baseGrangTotal: '',
      productViewOptionsMap: {}, // cash options
      q: '',
      isAllowJuniorToCheckout: false,
    }
    this.tpls = {
      bottomCartTemplate,
      shoppingListTemplate,
      tbodyTemplate,
      searchResultContainerTemplate,
      searchResultItemTemplate,
      editOptionsModalTemplate,
      skuSearchResultTemplate,
      renameModalTemplate,
      setRectangle,
      swatch,
      date,
      inputCheckbox,
      inputFile,
      inputNumbers,
      inputText,
      productList,
      textarea,
      setSelect,
      setRadio,
    }

    this.stencilUtils = stencilUtils
    this.listenOptionChange = listenOptionChange
    this.offOptionChangeHook = offOptionChangeHook
    this.currencyCodeFormat = currencyCodeFormat
    this.getCalculatedPrice = this.utils.getCalculatedPrice
    this.getModifierAdjuster = this.utils.getModifierAdjuster
    this.isAllRequiredOptionFilled = isAllRequiredOptionFilled
    this.modifierParentIdSeparate = modifierParentIdSeparate
  }

  get isShowEdit() {
    const {
      isOwner,
      status,
    } = this.state
    const {
      B3RoleId,
    } = this.utils.B3Storage
    const {
      constants: {
        B3Role: {
          SALESREP,
        },
      },
    } = this.utils
    return (
      !(
        B3RoleId.value === SALESREP
        && isOwner !== '1'
      )
      && +status !== 40
    )
  }

  get isShowUpdateStatus() {
    const {
      B3RoleId,
      B3CompanyId,
    } = this.utils.B3Storage
    const {
      status,
    } = this.state

    const {
      constants: {
        B3Role: {
          ADMIN,
          SENIOR,
          SALESREP,
        },
      },
    } = this.utils

    const roleId = B3RoleId.value

    return (
      (
        [ADMIN, SENIOR].includes(roleId)
        || (roleId === SALESREP && B3CompanyId.value)
      )
      && +status === 40
    )
  }

  get optionTemplateMap() {
    return {
      'set-rectangle': this.tpls.setRectangle,
      swatch: this.tpls.swatch,
      date: this.tpls.date,
      'input-checkbox': this.tpls.inputCheckbox,
      'input-file': this.tpls.inputFile,
      'input-numbers': this.tpls.inputNumbers,
      'input-text': this.tpls.inputText,
      'product-list': this.tpls.productList,
      'set-radio': this.tpls.setRadio,
      'set-select': this.tpls.setSelect,
      textarea: this.tpls.textarea,
    }
  }

  get isShowSelectAll() {
    const {
      B3RoleId,
    } = this.utils.B3Storage
    const {
      status,
    } = this.state

    const {
      constants: {
        B3Role: {
          ADMIN,
          SENIOR,
          JUNIOR,
          SALESREP,
        },
      },
    } = this.utils

    const roleId = B3RoleId.value

    return (
      (roleId === JUNIOR
        && +status === 30)
      || ([ADMIN, SENIOR].includes(roleId)
        && +status !== 40)
      || (roleId === SALESREP && +status !== 40)
    )
  }

  get isShowAddToCart() {
    const {
      status,
    } = this.state
    const {
      B3RoleId,
    } = this.utils.B3Storage

    const roleId = B3RoleId.value
    const {
      constants: {
        B3Role: {
          JUNIOR,
        },
      },
    } = this.utils
    return (
      roleId !== JUNIOR
      && +status !== 40
    )
  }

  get isShowClearCart() {
    const {
      B3RoleId,
    } = this.utils.B3Storage
    const roleId = B3RoleId.value
    const {
      constants: {
        B3Role: {
          JUNIOR,
        },
      },
    } = this.utils
    return (roleId !== JUNIOR)
  }

  get isShowAddToList() {
    const {
      status,
    } = this.state

    return +status !== 40
  }

  get isShowSubmitToApproval() {
    const {
      status,
    } = this.state
    const {
      B3RoleId,
    } = this.utils.B3Storage

    const roleId = B3RoleId.value
    const {
      constants: {
        B3Role: {
          JUNIOR,
        },
      },
    } = this.utils
    return (
      roleId === JUNIOR
      && +status === 30
    )
  }

  get isGetAllowJuniorPlaceOrders() {
    const {
      status,
    } = this.state
    const {
      B3RoleId,
    } = this.utils.B3Storage

    const roleId = B3RoleId.value
    const {
      constants: {
        B3Role: {
          JUNIOR,
        },
      },
    } = this.utils
    return (
      roleId === JUNIOR
      && +status === 0
    )
  }

  async init() {
    const {
      urlHelper,
    } = this.utils
    if (!this.isB2BUser) return

    const shoppingListId = urlHelper.searchParams.get('list_id')
    if (!shoppingListId) {
      urlHelper.redirect('/shopping-lists/')
      return
    }

    this.setState({
      shoppingListId,
    })

    this.setDefaultCurrency()

    await this.getShoppingListExtension()

    await this.allowJuniorToCheckout()

    this.renderBottomCart()
    // this.setStatusSelector()
    this.getStatus()
    this.render()
    this.utils.themeStyleFix.editModalBoxStyleFix()
  }

  setDefaultCurrency() {
    const currencyInfo = sessionStorage.getItem('currencyInfo')
    const { currencies: B3Currencies } = currencyInfo ? JSON.parse(currencyInfo) : { currencies: [] }
    const defaultCurrency = B3Currencies.find(({ is_default }) => (is_default))
    this.setState({
      defaultCurrency,
    })
  }

  renderBottomCart() {
    const options = {
      template: {
        content: 'b3/b3json',
        totals: 'cart/totals',
        pageTitle: 'cart/page-title',
        statusMessages: 'cart/status-messages',
      },
    }

    window.B3Spinner.show()
    this.stencilUtils.api.cart.getContent(options, (err, response) => {
      if (err) {
        window.B3Spinner.hide()
        return
      }

      const {
        cart,
      } = JSON.parse(response.content) || {}

      this.setState({
        cart,
      })

      const $bottomCart = document.querySelector('.bottom-cart-container')
      if ($bottomCart) $bottomCart.remove()

      this.utils.renderTemplate({
        hbsTemplate: this.tpls.bottomCartTemplate,
        containerSelector: 'footer',
        templateConfig: {
          cart,
          isShowAddToCart: this.isShowAddToCart,
          isShowClearCart: this.isShowClearCart,
        },
        insertType: 'beforeend',
      })

      this.setFooterPosition()
      this.bindCartEvents()
    })
  }

  setFooterPosition() {
    const $banner = document.querySelector('#consent-manager')
    if ($banner) {
      const $cartContent = document.querySelector('.bottom-cart-container')
      $cartContent.style.bottom = '-66px'
    }
  }

  async getShoppingListExtension() {
    const {
      shoppingListId: id,
      defaultCurrency,
      pagination: {
        offset,
        limit,
      },
    } = this.state

    window.B3Spinner.show()
    try {
      const q = document.querySelector('#sl-search-input')?.value?.trim()

      const resp = await this.api.getShoppingListItemsExtension({
        id,
        offset,
        limit,
        q,
      })

      const {
        createdAt,
        updatedAt,
        description,
        products,
        grandTotal,
      } = resp
      const {
        currencyFormat,
      } = this.utils

      let productQty = 0

      if (products.length > 0) {
        products.forEach(product => {
          productQty += product.qty
        })
      }
      this.setState({ productTotalQty: productQty })

      const timeConvert = timestamp => new Date(parseInt(timestamp, 10)).toLocaleDateString().replace(/\//g, '/')

      const createdAtTime = createdAt ? timeConvert(createdAt) : ''
      const updatedAtTime = updatedAt ? timeConvert(updatedAt) : ''
      const formatGrandTotal = currencyFormat(grandTotal, true, defaultCurrency)

      this.setState({
        ...resp,
        createdAtTime,
        updatedAtTime,
        description: description ? description.trim() : '',
        products: products.map(product => ({
          ...product,
          checked: '',
        })),
        baseGrangTotal: formatGrandTotal,
        q,
      })
    } catch {
      this.utils.Alert.error(this.locales.tips.globalError)
    }
  }

  render() {
    const {
      state,
      doms: {
        shoppinglist: {
          container,
        },
      },
      utils: {
        renderTemplate,
      },
      isShowEdit,
      isShowUpdateStatus,
      isShowSelectAll,
      isShowAddToList,
      isShowSubmitToApproval,
    } = this

    const $b2bWrap = document.querySelector('.b2b-wrap')
    if ($b2bWrap) $b2bWrap.remove()

    renderTemplate({
      hbsTemplate: this.tpls.shoppingListTemplate,
      containerSelector: container,
      templateConfig: {
        ...state,
        isShowEdit,
        isShowUpdateStatus,
        isShowSelectAll,
        isShowAddToList,
        isShowSubmitToApproval,
      },
      insertType: 'beforeend',
    })

    this.renderShoppingList()
    this.bindEvents()
    this.submitBtn()
    // this.listenOptionChange()
  }

  // setStatusSelector() {
  //   const {
  //     B3RoleId,
  //     B3CompanyId,
  //   } = this.utils.B3Storage
  //   const {
  //     status,
  //   } = this.state
  //   const {
  //     ADMIN,
  //     SENIOR,
  //     JUNIOR,
  //     SALESREP,
  //   } = this.B3Role

  //   const roleId = B3RoleId.value

  //   let options = []
  //   const getStatusSelecttOptions = (keys = [], selectedKey) => keys.reduce((result, key) => {
  //     result.push({
  //       value: key,
  //       title: B3ShoppListStatus[key],
  //       selected: +key === +selectedKey ? 'selected' : '',
  //     })
  //     return result
  //   }, [])

  //   if (roleId === JUNIOR && +status === 30) {
  //     options = getStatusSelecttOptions([status, 40], 30)
  //   } else if (
  //     +status === 40
  //       && (
  //         [ADMIN, SENIOR].includes(roleId)
  //         || (roleId === SALESREP && B3CompanyId.value)
  //       )
  //   ) {
  //     options = getStatusSelecttOptions([status, 0, 30], status)
  //   } else options = getStatusSelecttOptions([status])

  //   this.setState({
  //     statusSelectOptions: options,
  //   })
  // }

  getStatus() {
    const {
      B3RoleId,
      B3CompanyId,
    } = this.utils.B3Storage
    const {
      status,
    } = this.state

    const {
      constants: {
        B3ShoppListStatus,
        B3Role: {
          ADMIN,
          SENIOR,
          JUNIOR,
          SALESREP,
        },
      },
    } = this.utils
    const roleId = B3RoleId.value
    let options = []
    const getStatusTitle = (keys = [], title) => keys.reduce((result, key) => {
      result.push({
        value: key,
        title: title || B3ShoppListStatus[key],
      })
      return result
    }, [])
    if (roleId === JUNIOR && +status === 30) {
      options = getStatusTitle([status])
    } else if (+status === 0
      && (
        [ADMIN, SENIOR].includes(roleId)
        || (roleId === SALESREP && B3CompanyId.value)
      )
    ) {
      options = getStatusTitle([status], this.text['shopping.list.status.approved'])
    } else options = getStatusTitle([status])

    this.setState({
      statusTitle: options,
    })
  }

  afterRender() {
    const {
      B3RoleId,
    } = this.utils.B3Storage
    const {
      status,
      renameModal,
    } = this.state
    const {
      constants: {
        B3Role: {
          ADMIN,
          SENIOR,
          JUNIOR,
          SALESREP,
        },
      },
    } = this.utils

    const roleId = B3RoleId.value

    const $updates = document.querySelectorAll('[update-list-items')
    const $deleteAll = document.querySelector('[data-delete-items]')
    const $rightLayout = document.querySelector('.b2b-column-right')

    if (
      (roleId === JUNIOR && +status !== 30)
      || ([ADMIN, SENIOR].includes(roleId) && +status === 40)
      || (roleId === SALESREP && +status === 40)
    ) {
      $deleteAll.remove()
      $updates.forEach($update => $update.remove)
      $rightLayout.style.width = '100%'
    }

    this.hideReadyApprovalBtn()

    const $quickAdd = document.querySelector('#quick_add_section')
    const $rename = document.querySelector('[data-rename-list]')

    if (
      ([ADMIN, SENIOR, SALESREP].includes(roleId) && +status === 40)
      || (roleId === JUNIOR && +status !== 30)
    ) {
      if ($quickAdd) $quickAdd.remove()
      if ($rename) $rename.remove()
    }

    if ($rename) {
      $rename.addEventListener('click', () => {
        renameModal.open()

        const renameModalContent = this.tpls.renameModalTemplate(this.state)

        renameModal.setContent(renameModalContent)
        this.bindRename()
      })
    }
    window.B3Spinner.hide()
  }

  hideReadyApprovalBtn() {
    const {
      isAppendStyle,
      status,
    } = this.state

    const frageStyle = `
        <style>
        [update-list-items],.action-lists{
            display:none!important;
        }
        .col-checkbox{
            opacity:0;
        }
        </style>
    `

    if (+status === 40 && !isAppendStyle) {
      this.utils.renderTemplate({
        template: frageStyle,
        containerSelector: 'head',
      })
      this.setState({
        isAppendStyle: true,
      })
    }
  }

  async renderShoppingList() {
    const {
      products,
      status,
    } = this.state
    const {
      B3RoleId,
      B3CompanyId,
    } = this.utils.B3Storage
    const {
      constants: {
        B3Role: {
          JUNIOR,
          ADMIN,
          SENIOR,
          SALESREP,
        },
      },
    } = this.utils

    const roleId = B3RoleId.value

    const isShowAction = (
      (
        [ADMIN, SENIOR].includes(roleId)
        || (roleId === SALESREP && B3CompanyId.value)
      )
      && +status === 0
    ) || (roleId === JUNIOR && +status === 30)
    const inputDisabled = isShowAction ? '' : 'disabled'

    const productIds = []

    const list = products.map((product, idx) => {
      const {
        optionsList,
        variantSku,
        baseSku,
        basePrice,
        productId,
        qty,
        itemId,
        variantId,
      } = product

      const productImage = product?.primaryImage?.urlThumbnail || DEFAULT_IMAGE_URL
      const productSku = variantSku ?? baseSku
      const productPrice = parseFloat(basePrice).toFixed(2)
      const options = JSON.stringify(optionsList)

      productIds.push({
        productId,
        variantId,
        idx,
        options,
        qty,
        optionsList,
        itemId,
        basePrice,
      })

      return {
        ...product,
        productImage,
        productSku,
        productPrice,
        idx,
        options,
        isShowAction,
        inputDisabled,
      }
    })

    document.querySelector('#shopping_list_table tbody').innerHTML = this.tpls.tbodyTemplate({
      list,
    })

    this.utils.on('#shopping_list_table tbody tr', 'input', 'qty', () => {
      // if (target.classList.contains('disabled')) return
    })

    this.afterRender()

    await this.renderProductDetail(productIds)

    this.renderPaginator()
    this.bindUpdateItem()
    this.bindDeleteItem()
    this.bindEditOptions()
    this.bindCheckBox()
    this.renderShoppingListCount()
  }

  renderProductDetail = async productIds => {
    const {
      status,
    } = this.state
    const {
      B3RoleId,
    } = this.utils.B3Storage

    const productCollection = []

    productIds.forEach(productIdItem => {
      const {
        productId,
        qty,
        optionsList,
        idx,
        basePrice,
      } = productIdItem

      if (+qty === 0) {
        const $tr = document.querySelector(`[data-index-${idx}]`)
        const $checkbox = $tr.querySelector('.col-checkbox > input') || {}

        $checkbox.disabled = true
        $checkbox.title = this.text['tips.shoppingListNumberLimit']

        const $priceValue = $tr.querySelector('[data-product-price-value]')
        $priceValue.setAttribute('data-product-price-value', this.utils.currencyFormat(basePrice))
        $priceValue.innerHTML = `<span class="product-price">${this.utils.currencyFormat(basePrice)}</span>`

        $tr.querySelector('.product-subtotal').innerHTML = basePrice * qty

        return
      }

      const getById = new Promise((resolve, reject) => this.stencilUtils.api.product.getById(productId, {
        template: 'b3/b3json',
      }, (err, response) => {
        if (err) {
          const $tr = document.querySelector(`[data-index-${idx}]`)
          this.setPricesInfo({}, $tr, true, optionsList, qty, productIdItem)

          reject(err)
        }

        const {
          product,
        } = JSON.parse(response)

        const $tr = document.querySelector(`[data-index-${idx}]`)
        const $price = $tr.querySelector('.product-price')
        const {
          url: productUrl,
          options,
          price,
        } = product

        productIdItem.price = price // cache bc price

        this.setPricesInfo(product, $tr, true, optionsList, qty, productIdItem)

        $tr.querySelectorAll('[product-url]').forEach(a => {
          a.href = productUrl
        })

        let optionHtml = ''
        const pickListArr = []
        const productIds = []

        if (!options?.length) resolve()
        options?.forEach(option => {
          const {
            id,
            required,
            partial,
            display_name: displayName,
            values,
          } = option

          let optionExist = false

          let isDateAssembleComplete = !partial === 'date'
          const dateSource = {}

          optionsList.forEach(({
            option_id: optionId,
            option_value: optionValue,
          }) => {
            const attrId = `attribute[${id}]`
            if (attrId === optionId) {
              optionExist = true

              if (['input-text', 'textarea', 'input-numbers'].includes(partial)) {
                // support modifier Text & Multi-Line Text
                optionHtml += `<span class="option-name">${displayName}:</span> ${optionValue} </br>`
              } else if (partial === 'input-checkbox') {
                optionHtml += `<span class="option-name">${displayName}:</span> ${this.text['shopping.list.inputCheckbox.yes']} </br>`
              } else if (values) {
                values.forEach(value => {
                  if (+value.id === +optionValue) {
                    optionHtml += `<span class="option-name">${displayName}:</span> ${value.label} </br>`
                    // pick list option
                    if (partial === 'product-list') {
                      const pickedProductId = value.data
                      pickListArr.push({
                        pickedOptionId: id,
                        pickedOptionValue: value.id,
                        pickedProductId,
                      })
                      productIds.push(pickedProductId)
                    }
                  }
                })
              }
            } else if (optionId.includes(attrId) && partial === 'date') {
              // support modifier Date
              optionExist = true
              dateSource[optionId.split(attrId)[1]] = optionValue
              const year = dateSource['[year]']
              const month = dateSource['[month]']
              const day = dateSource['[day]']
              isDateAssembleComplete = year && month && day
              if (isDateAssembleComplete) {
                const displayDate = window.B3DisplayFormat(new Date(`${year}/${month}/${day}`))
                optionHtml += `<span class="option-name">${displayName}:</span> ${displayDate} </br>`
              }
            }
          })

          if (required && !optionExist) {
            optionHtml += `<span class="option-name">${displayName}:</span> <i class="no-option-value-tip" no-option-value>${this.text['shopping.list.edit.options']}</i> </br>`
          }
        })

        if (productIds && productIds.length > 0) {
          this.getTierPriceByProductIdMulti(productIds, qty, () => {
            this.getVariantOptions($tr, $price, pickListArr, optionsList)
          })
        }
        $tr.querySelector('.product-options').innerHTML = optionHtml

        if (options?.length) {
          const {
            constants: {
              B3Role: {
                JUNIOR,
              },
            },
          } = this.utils
          if (
            (+status !== 40 && B3RoleId.value !== JUNIOR)
            || (+status === 30 && B3RoleId.value === JUNIOR)
          ) {
            $tr.querySelector('.action-lists .list-button-remove').insertAdjacentHTML('beforebegin', `<a class="button button--primary button--small edit-option ${this.classes['shoppinglist.right.button.editOption']}" href="#"><i class="fa fa-edit edit-option ${this.classes['shoppinglist.right.button.editOption.icon']}"></i>${this.text['shopping.list.button.editOptions']}</a>`)
          }
        }
        resolve()
      }))

      productCollection.push(getById)
    })
    await Promise.allSettled(productCollection)
  }

  getTierPriceByProductIdMulti = (productIds, qty, cb) => {
    const {
      gTierPrice,
    } = this.state

    const productId = productIds[productIds.length - 1]

    if (gTierPrice[productId]) {
      productIds.pop()
      if (productIds.length === 0) {
        if (cb) {
          cb()
        }
      } else {
        this.getTierPriceByProductIdMulti(productIds, qty, cb)
      }
    } else {
      this.stencilUtils.api.product.getById(productId, {
        template: 'b3/b3json',
      }, (err, response) => {
        if (err) return
        const {
          product,
        } = JSON.parse(response)
        const priceContainer = product.price.without_tax || product.price.with_tax
        const basePrice = priceContainer.formatted
        this.setState({
          gTierPrice: {
            ...gTierPrice,
            [productId]: basePrice,
          },
        })
        productIds.pop()
        if (productIds.length === 0) {
          if (cb) {
            cb()
          }
        } else {
          this.getTierPriceByProductIdMulti(productIds, qty, cb)
        }
      })
    }
  }

  getVariantOptions($tr, $priceSpan, pickListArr, options) {
    const {
      currencyFormat,
    } = this.utils

    const gMasterPrcie = $priceSpan.dataset.mainPrice

    let productPrice = parseFloat(gMasterPrcie).toFixed(2)
    if (options) {
      pickListArr.forEach(({
        pickedOptionId,
        pickedOptionValue,
        pickedProductId,
      }) => {
        let showCustomPrice = true

        options.forEach(({
          option_id: optionId,
          option_value: optionValue,
        }) => {
          if (pickedOptionId === optionId && pickedOptionValue === optionValue) {
            showCustomPrice = false
          }
        })

        if (showCustomPrice) {
          const pickListProductPrice = this.state.gTierPrice[pickedProductId] || 0
          productPrice = parseFloat(parseFloat(productPrice) + parseFloat(pickListProductPrice)).toFixed(2)
        }
      })
    }

    $priceSpan.innerHTML = (`${currencyFormat(parseFloat(productPrice))}`)
    // for list
    if ($tr.querySelectorAll('.product-subtotal-span').length > 0) {
      const qty = $tr.querySelector('input.qty').value
      const totlePriceValue = currencyFormat(parseFloat(qty * productPrice).toFixed(2))
      $tr.querySelector('.product-subtotal-span').text = totlePriceValue
    }
  }

  renderPaginator() {
    const {
      pagination: {
        offset,
        limit,
        totalCount,
      },
    } = this.state

    const currentPage = Math.ceil((offset + 1) / limit)
    const totalPages = Math.ceil(totalCount / limit)

    window.B3Paginator.init({
      container: '#shopping-list-pagination',
      currentPage,
      totalPages,
      onPageChange: this.handleShoppingListPaginationChange,
    })
  }

  handleShoppingListPaginationChange = async page => {
    const {
      pagination,
      pagination: {
        limit,
      },
    } = this.state

    // BB2BV30-1478 Handle search sl, page is a event Obj
    const currentPage = typeof page === 'object' ? 1 : page

    this.setState({
      pagination: {
        ...pagination,
        offset: (currentPage - 1) * limit,
      },
    })

    await this.getShoppingListExtension()
    this.renderShoppingList()
  }

  bindEvents() {
    this.bindSingleSearch()
    this.bindSelectAll()
    this.bindDeleteAll()
    this.bindSkuSearch()
    this.bindStatusChange()
    this.bindAddToShoppingList()
    this.bindUploadCSV()
  }

  bindCheckBox() {
    document.querySelectorAll('.col-checkbox input').forEach(item => {
      item.addEventListener('change', () => {
        const allCheked = this.isAllChecked()
        const selectAll = document.querySelector('#select_all')

        if (allCheked) {
          return selectAll.checked = true
        }
        selectAll.checked = false
      })
    })
  }

  isAllChecked() {
    const items = document.querySelectorAll('.col-checkbox input')
    let status = true
    items.forEach(item => {
      if (!item.checked) status = false
    })
    return status
  }

  bindUploadCSV() {
    const $CSV = document.querySelector('#customer_sku_csv')
    if ($CSV) {
      $CSV.addEventListener('change', e => {
        const { target } = e
        const reg = new RegExp('[.](csv)$')
        let uploadFile
        let originArr = []
        let errorCounter = 0
        const parsedata = []
        const $csvCheckInfoContainer = document.querySelector('#csv_check_info')

        if (target.files && target.files[0]) {
          [uploadFile] = target.files
        } else {
          return false
        }

        if (!reg.test(uploadFile.name)) {
          return this.utils.Alert.error(this.locales.validation.uploadNotCsv)
        }

        const reader = new FileReader()

        reader.addEventListener('load', b => {
          const csvdata = b.target.result
          $csvCheckInfoContainer.innerHTML = `<p class="checking-tips">${this.text['qop.checking.file']}</p>`

          window.B3Spinner.show()

          if (csvdata) {
            originArr = csvdata.split('\n')
          }

          this.removeEmptyRow(originArr)
          const unEmptyArr = originArr

          let columns = 0

          if (unEmptyArr && unEmptyArr.length > 0) {
            if (unEmptyArr.length > 300) {
              $csvCheckInfoContainer.innerHTML = `<div class="checking-info-box">${this.text['shopping.list.upload.limit.errorMessage']}</div>`
              window.B3Spinner.hide()
              $CSV.value = ''
              return null
            }

            const headerRow = unEmptyArr[0]
            const headerArr = headerRow.split(',')
            // ["variant_sku", "qty", "options", "", ""]
            this.removeEmptyRow(headerArr)
            columns = headerArr.length
          } else {
            $csvCheckInfoContainer.innerHTML = `<div class="checking-info-box">${this.text['shopping.list.upload.errorMessage']}</div>`
            window.B3Spinner.hide()
            $CSV.value = ''
            return null
          }
          for (let i = 1; i < unEmptyArr.length; i += 1) {
            const productIdsArr = ''
            const dataItem = unEmptyArr[i].split(',')

            this.removeEmptyRow(dataItem)

            let errorInfo = ''
            if (dataItem.length > columns) {
              errorInfo += this.text['qop.redundant.data']
            } else {
              dataItem.length = columns
            }
            if (!dataItem[0]) {
              errorInfo += this.text['qop.empty.sku']
            }
            if (!(dataItem[1]).replace(/[\r\n]/g, '') || (dataItem[1]).replace(/[\r\n]/g, '') === '0') {
              errorInfo += this.text['qop.empty.qty']
            }
            if (/\./.test(dataItem[1]) || /\\-/.test(dataItem[1])) {
              errorInfo += this.text['qop.integer.qty']
            }
            if (errorInfo.trim() !== '') {
              errorCounter += 1
              const el = document.createElement('div')
              el.innerHTML = `${this.utils.text('qop.row.errorInfo', {
                hash: {
                  index: i + 1,
                  errorInfo,
                },
              })}`
              $csvCheckInfoContainer.append(el)
            }
            const productDataArr = productIdsArr.concat(dataItem)
            parsedata.push(productDataArr)
          }

          if (errorCounter === 0) {
            // advQty check
            const csvdataArr = parsedata.map(item => ({
              sku: item.split(',')[0],
              qty: Number.parseInt(item.split(',')[1], 10),
            }))
            const keywords = []
            parsedata.forEach(item => {
              keywords.push(item.split(',')[0])
            })
            let variantSkus = []
            const newData = []
            csvdataArr.forEach(item => {
              variantSkus.push(item.sku)
            })
            variantSkus = Array.from(new Set(variantSkus))

            this.api.getProductsBySkuQuickByPost({ variantSkus }).then(res => {
              res.forEach(item => {
                csvdataArr.forEach(cItem => {
                  if (item.variantSku === cItem.sku && !(item.purchasingDisabled * 1)) {
                    newData.push([
                      item.productId,
                      item.variantId,
                      item.variantSku,
                      cItem.qty,
                      item.option ? item.option : '',
                    ])
                  }
                })
              })
              if (newData.length > 0) {
                $csvCheckInfoContainer.innerHTML = `<div>${this.text['shopping.list.file.processed']}</div>`

                const itemArr = csvdataArr
                const variantSkus = itemArr.map(item => item.sku)
                const qtyArr = itemArr.map(item => item.qty)

                this.api.getAdvQtyState().then(() => this.api.getAdvQtyBySkusNew({ variantSkus }), () => {
                  $CSV.value = ''
                  this.addCsvProductsToList(newData)
                  return []
                }).then(res => {
                  let invalideQtyCount = 0
                  variantSkus.forEach((sku, idx) => {
                    if (!res.data) {
                      return
                    }
                    const match = res.data.productQuantityList.filter(row => row.variantSku === sku)
                    if (match.length === 0) return
                    const qtyInfo = match[0]
                    const qty = Number.parseInt(qtyArr[idx], 10) || 0
                    const qtyMin = this.getMinQty(qtyInfo.minOrderQty, qtyInfo.qtyIncrement)

                    const qtyIncrement = Number.parseInt(qtyInfo.qtyIncrement, 10) || 1

                    if (qty < qtyMin || (qty % qtyIncrement) !== 0) {
                      invalideQtyCount += 1
                    }
                  })

                  if (invalideQtyCount > 0) {
                    const el = document.createElement('div')
                    el.innerHTML = `<div style="font-weight:600;">${this.text['shopping.list.fileCheck.errorMessage']}</div>`
                    $csvCheckInfoContainer.append(el)
                    $csvCheckInfoContainer.querySelector('.checking-tips').remove()
                  } else {
                    $csvCheckInfoContainer.innerHTML = `<div>${this.text['shopping.list.file.processed']}</div>`
                    $CSV.value = ''
                    this.addCsvProductsToList(newData)
                  }
                }, () => {
                  const el = document.createElement('div')
                  el.innerHTML = `<div style="font-weight:600;">${this.text['shopping.list.fileCheck.errorMessage']}</div>`
                  $csvCheckInfoContainer.append(el)
                  $csvCheckInfoContainer.querySelector('.checking-tips').remove()
                }).catch(error => {
                  this.utils.Alert.error(error)
                })
                .finally(() => $CSV.value = '')
              } else {
                $csvCheckInfoContainer.innerHTML = `<div class="checking-info-box">${this.text['shopping.list.fileProcessed.errorMessage']}</div>`
                window.B3Spinner.hide()
                $CSV.value = ''
              }
              return newData
            })
          } else {
            const el = document.createElement('div')
            el.innerHTML = `${this.utils.text('shopping.list.fileUpload.errorMessage', {
              hash: {
                errorCounter,
              },
            })}`
            $csvCheckInfoContainer.append(el)
            $csvCheckInfoContainer.querySelector('.checking-tips').remove()
            window.B3Spinner.hide()
            $CSV.value = ''
            return parsedata
          }
        })

        return reader.readAsBinaryString(uploadFile)
      })
    }
  }

  addCsvProductsToList(products) {
    const {
      shoppingListId,
    } = this.state
    const data = {
      id: shoppingListId,
      items: [],
    }
    products.forEach(item => {
      const optionList = []
      item[4].forEach(optionListItem => {
        optionList.push({
          option_id: `attribute[${optionListItem.optionId || optionListItem.option_id}]`,
          option_value: `${optionListItem.id}`,
        })
      })
      data.items.push({
        productId: item[0],
        variantId: item[1],
        qty: item[3],
        optionList,
      })
    })
    this.addToShoppingListByCSV(data, true)
  }

  async addToShoppingListByCSV(item, last) {
    const {
      pagination,
    } = this.state
    try {
      await this.api.addProductToShoppingList(item)
      if (last) {
        this.utils.Alert.success(this.locales.tips.addToShoppingListSuccess)
        this.setState({
          pagination: {
            ...pagination,
            offset: 0,
          },
        })
        await this.getShoppingListExtension()
        this.render()
      }
    } catch {
      this.utils.Alert.error(this.locales.tips.globalError)
    }
    window.B3Spinner.hide()
  }

  getMinQty(minOrder, increment) {
    let minQty
    if (minOrder === 0 || increment === 0) {
      minQty = minOrder || increment
    } else {
      minQty = minOrder % increment === 0 ? minOrder : (Number.parseInt(minOrder / increment, 10) + 1) * increment
    }
    return minQty
  }

  isEmptyRow(arr) {
    const tmpArr = arr.split(',')
    for (let k = 0; k < tmpArr.length; k += 1) {
      if (tmpArr[k]) {
        return false
      }
    }
    return true
  }

  removeEmptyRow(arr) {
    const tmpArr = arr
    if (this.isEmptyRow(tmpArr[tmpArr.length - 1])) {
      tmpArr.pop()
      return this.removeEmptyRow(tmpArr)
    }
    return tmpArr
  }

  bindSingleSearch() {
    const $btn = document.querySelector('#search_single_sku')
    const $input = document.querySelector('#product_search_input')
    const $results = document.querySelector('#product_search_results')
    const $pagination = document.querySelector('#more-results')

    const setNotFound = () => {
      $pagination.innerHTML = ''
      $results.innerHTML = (`<div style="margin-bottom:1.5rem;text-align:center;">${this.text['shopping.list.products.notFound']}</div>`)
    }

    const handleSearch = () => {
      const searchQuery = $input.value

      if (searchQuery.length >= 2) {
        this.handleInputSearch(() => {
          if (searchQuery.length < 2) return null
          window.B3Spinner.show()
          $results.innerHTML = ''
          $pagination.innerHTML = ''

          this.stencilUtils.api.search.search(searchQuery, {
            template: 'b3/b3json',
          }, async (err, response) => {
            window.B3Spinner.hide()
            if (err) return setNotFound()
            const newResponse = handleStencilSearchInfo(response)
            const {
              product_results: {
                products = [],
              },
            } = JSON.parse(newResponse)
            const signleIds = []
            const optionIds = []

            if (products.length) this.listenOptionChange()

            products.forEach(({
              id,
              has_options: hasOptions,
            }) => (hasOptions ? optionIds.push({ id }) : signleIds.push({ id })))

            const { list } = await this.api.getInventory({
              products: signleIds.map(({ id: productId }) => ({
                productId,
              })),
            })

            const productIds = list.filter(({ purchasingDisabled }) => !purchasingDisabled).map(({ productId }) => ({ id: productId }))
            productIds.push(...optionIds)

            products.forEach(({ id: productId }, index) => {
              productIds.forEach(productIdItem => {
                const { id } = productIdItem
                if (productId === id) productIdItem.idx = index
              })
            })

            productIds.sort((a, b) => a.idx - b.idx)

            if (!productIds.length) return setNotFound()

            const limit = 3
            const totalPages = Math.ceil(productIds.length / limit)
            const currentPage = 1

            const searchProduct = productId => new Promise(resolve => {
              const resultContainer = this.tpls.searchResultContainerTemplate({
                productId,
              })
              $results.insertAdjacentHTML('beforeend', resultContainer)

              this.stencilUtils.api.product.getById(productId, {
                template: 'b3/b3json',
              }, (error, res) => {
                const $productId = $results.querySelector(`.product-${productId}`)
                $productId.classList.remove('loading-span')
                let optionsHTML = ''
                if (error) return
                const {
                  product,
                } = JSON.parse(res)

                this.setState({
                  productViewOptionsMap: {
                    ...this.state.productViewOptionsMap,
                    [productId]: product,
                  },
                })

                const options = product.options.map(option => {
                  const values = option.values ? option.values.map(value => ({
                    ...value,
                    checked: false,
                    selected: false,
                  })) : []
                  return {
                    ...option,
                    checked: false,
                    values,
                  }
                })
                optionsHTML = this.renderOptions(options, productId)

                const resultHtml = this.tpls.searchResultItemTemplate({
                  showCheckBox: true,
                  showSelect: false,
                  product: {
                    ...product,
                    ...this.utils.getCorrectProductImage(product, 'main_image'),
                    optionsHTML,
                  },

                })
                $productId.innerHTML = resultHtml
                resolve()
              })
            })
            const showProduct = page => {
              $results.innerHTML = ''
              const $active = document.querySelector('#more-results .pagination-item--current')
              const $pageItems = document.querySelectorAll('#more-results .pagination-item')
              const $prev = document.querySelector('#more-results .pagination-item--previous')
              const $next = document.querySelector('#more-results .pagination-item--next')
              $active && $active.classList.remove('pagination-item--current')
              $pageItems.length && $pageItems[page].classList.add('pagination-item--current')
              $prev && ($prev.setAttribute('data-page', +page - 1), +page === 1 ? $prev.classList.add('disabled') : $prev.classList.remove('disabled'))
              $next && ($next.setAttribute('data-page', +page + 1), +page === totalPages ? $next.classList.add('disabled') : $next.classList.remove('disabled'))

              const currentPageProduct = productIds
                .filter((productIdItem, i) => i >= (page - 1) * limit && i < page * limit)
              if (currentPageProduct.length) $results.innerHTML = ` <div  class="product-qty-label form-label" style="text-align: right" >${this.text['shopping.list.qty']}<small>*</small></div>`
              currentPageProduct.map(({ id }) => searchProduct(id))
            }

            showProduct(1)

            window.B3Paginator.init({
              container: '#more-results',
              currentPage,
              totalPages,
              onPageChange: showProduct,
            })
          })
        })
      } else if (searchQuery.length === 0) {
        $results.innerHTML = ''
      }
    }
    if ($btn) $btn.addEventListener('click', handleSearch)
    if ($input) {
      $input.addEventListener('keydown', e => {
        if (e.keyCode === 13) {
          handleSearch()
        }
      })
    }
  }

  renderOptions(options, parentId) {
    return options.reduce((html, currentOption) => {
      const uniqueId = `${new Date().getTime()}${parentId ? `${this.modifierParentIdSeparate}${parentId}` : ''}`
      currentOption.uniqueId = uniqueId
      currentOption.values?.forEach(value => {
        value.uniqueId = uniqueId
      })
      html += this.optionTemplateMap[currentOption.partial](currentOption)
      return html
    }, '')
  }

  bindRename() {
    const {
      shoppingListId,
      status,
      renameModal,
    } = this.state

    const $content = renameModal.getContent()

    const $rename = $content.querySelector('#rename_list')
    const $closes = document.querySelectorAll('.modal-close')

    $closes.forEach($close => $close.addEventListener('click', () => renameModal.close()))

    $rename.addEventListener('click', async () => {
      const $form = $content.querySelector('form')
      const name = $form.querySelector('#list_name').value
      const description = $form.querySelector('#list_comment').value || ''

      if (name === '') {
        this.utils.Alert.error(this.locales.validation.emptyShoppingListName)
        return
      }

      renameModal.close()
      window.B3Spinner.show()
      try {
        await this.api.updateShoppingList({
          name,
          description,
          status,
          id: shoppingListId,
        })
        this.utils.Alert.success(this.locales.tips.shoppingListUpdateSuccess)

        this.setState({
          name,
          description,
        })
        this.render()
      } catch {
        this.utils.Alert.error(this.locales.tips.globalError)
      }
      window.B3Spinner.hide()
    })
  }

  bindSelectAll() {
    const $selectAll = document.querySelector('#select_all')
    const $shoppingListTable = document.querySelector('#shopping_list_table')

    if ($selectAll) {
      $selectAll.addEventListener('click', () => {
        const $selects = $shoppingListTable.querySelectorAll('.col-checkbox input[type=checkbox]')
        const isChecked = $selectAll.checked
        $selects.forEach(checkbox => checkbox.checked = isChecked)
        $shoppingListTable.querySelectorAll('.col-checkbox input[type=checkbox]:disabled').forEach(checkbox => checkbox.checked = false)
      })
    }
  }

  bindEditOptions() {
    const {
      editOptionsModal,
    } = this.state

    this.utils.on('#shopping_list_table tbody tr', 'click', 'edit-option', ($tr, target, e) => {
      e.preventDefault()
      e.stopPropagation()
      const {
        productId,
        variantId,
        index: itemIndex,
        productOptions: itemOptions,
        itemId,
      } = $tr.dataset

      const skuHtml = $tr.querySelector('.product-sku').innerHTML
      this.setState({
        shopingListItemId: itemId,
      })

      window.B3Spinner.show()

      this.stencilUtils.api.product.getById(productId, {
        template: 'b3/b3json',
      }, (err, response) => {
        window.B3Spinner.hide()
        if (err) return
        let optionsHTML = ''
        const {
          product = {},
        } = JSON.parse(response) || {}
        optionsHTML = this.renderOptions(product.options, productId)
        this.setState({
          productViewOptionsMap: {
            ...this.state.productViewOptionsMap,
            [productId]: product,
          },
        })

        const modalContent = this.tpls.editOptionsModalTemplate({
          product: {
            ...product,
            ...this.utils.getCorrectProductImage(product, 'main_image'),
            optionsHTML,
          },
        })

        editOptionsModal.open()
        editOptionsModal.setContent(modalContent)
        const $modalContent = editOptionsModal.getContent()

        $modalContent.querySelector('#index_container').dataset.index = itemIndex
        $modalContent.querySelector('#variant_id_container').dataset.variantId = variantId
        $modalContent.querySelector('[data-product-sku]').innerHTML = skuHtml

        const $close = $modalContent.querySelector('.modal-close')
        if ($close) {
          $close.addEventListener('click', () => {
            editOptionsModal.close()
          })
        }

        // renderDefaultOptions
        this.renderDefaultOptions(itemOptions)
        this.renderEditOptionsPrice(itemOptions, productId, $modalContent)

        const $modal = document.querySelector('#option-modal')
        const tr = $modal.querySelector('tr')
        tr.setAttribute('data-variant-id', variantId)
        this.listenOptionChange()
      })
    })
  }

  renderDefaultOptions(itemOptions) {
    try {
      const options = JSON.parse(itemOptions)
      const optionsMap = {}
      options.forEach(option => {
        const {
          option_value,
          option_id,
        } = option
        optionsMap[option_id] = option_value
      })

      // render input fields
      const $allInputEle = document.querySelectorAll('#option-modal .option-form input')
      for (let i = 0; i < $allInputEle.length; i += 1) {
        if (!Object.keys(optionsMap).length) break

        const $formField = $allInputEle[i]
        const { name, value, type } = $formField
        const optionValue = optionsMap[name]
        if (!optionValue) continue

        if (['text', 'number'].includes(type)) {
          $formField.value = optionValue
          delete optionsMap[name]
          continue
        }

        if (+value === +optionValue) {
          $formField.checked = true
          delete optionsMap[name]
          continue
        }
      }

      // render select fields & textarea fields
      const $allSelectEle = document.querySelectorAll('#option-modal .option-form select')
      const $allTextareaEle = document.querySelectorAll('#option-modal .option-form textarea')
      const allEle = [...$allSelectEle, ...$allTextareaEle]
      for (let i = 0; i < allEle.length; i += 1) {
        if (!Object.keys(optionsMap).length) break

        const $formField = allEle[i]
        const { name } = $formField
        const optionValue = optionsMap[name]
        if (optionValue) {
          $formField.value = optionValue
          delete optionsMap[name]
        }
      }
    } catch {
      //
    }
  }

  renderEditOptionsPrice(productOptions, productId, $modalContent) {
    productOptions = JSON.parse(productOptions)

    const arr = []

    productOptions.forEach(({
      option_id,
      option_value,
    }) => {
      arr[option_id] = option_value
    })

    let frage = ''

    Object.keys(arr).forEach(key => {
      frage += `${encodeURIComponent(key)}=${encodeURIComponent(arr[key])}&`
    })

    this.stencilUtils.api.productAttributes.optionChange(productId, frage, async (err, result) => {
      if (err) {
        return this.utils.Alert.error(err)
      }
      const $priceContainer = $modalContent.querySelector('[data-product-price]')

      const { data: product } = result
      $priceContainer.innerHTML = product?.price.without_tax?.formatted
    })
  }

  bindDeleteAll() {
    const $shoppingListTable = document.querySelector('#shopping_list_table')
    const $deleteAll = document.querySelector('[data-delete-items]')

    const handleDelete = async () => {
      const $checkedInputs = $shoppingListTable.querySelectorAll('tbody tr input[type=checkbox]:checked')

      if ($checkedInputs.length === 0) {
        this.utils.Alert.error(this.locales.validation.emptyShoppingListSelect)
        return
      }

      const {
        dismiss,
      } = await this.utils.Alert.warning(this.locales.tips.confirmDeleteShoppingListItem, {
        showCancelButton: true,
        confirmButtonText: this.text['global.alert.buttonText.delete'],
        confirmButtonColor: '#ff0000',
      })

      if (dismiss) return

      $checkedInputs.forEach(($checked, index) => {
        const { itemId } = $checked.dataset
        const isLast = $checkedInputs.length === (index + 1)
        this.deletItem(itemId, isLast)
      })
    }

    if ($deleteAll) {
      $deleteAll.removeEventListener('click', handleDelete)
      $deleteAll.addEventListener('click', handleDelete)
    }
  }

  async handleSubmitForApproval() {
    const $shoppingListTable = document.querySelector('#shopping_list_table')
    const $checkedInputs = $shoppingListTable.querySelectorAll('tbody tr input[type=checkbox]')
    if ($checkedInputs.length === 0) {
      this.utils.Alert.error(this.locales.validation.emptyShoppingListSubmit)
      return
    }

    const {
      shoppingListId,
    } = this.state
    const {
      B3RoleId: {
        value: roleId,
      },
    } = this.utils.B3Storage
    const {
      constants: {
        B3Role: {
          ADMIN,
          SENIOR,
          JUNIOR,
          SALESREP,
        },
      },
    } = this.utils

    const selectedStatus = 40
    window.B3Spinner.show()
    try {
      await this.api.updateShoppingList({
        status: selectedStatus,
        id: shoppingListId,
      })
      await this.utils.Alert.success(this.locales.tips.updateShoppingListSuccess)
      if (
        (roleId === JUNIOR && +selectedStatus === 40)
        || ([ADMIN, SENIOR, SALESREP].includes(roleId) && +selectedStatus === 0)
      ) {
        window.location.reload()
      }
      if ([ADMIN, SENIOR, SALESREP].includes(roleId) && +selectedStatus === 30) {
        window.location.href = '/shopping-lists/'
      }
    } catch {
      this.utils.Alert.error(this.locales.tips.globalError)
    }
    window.B3Spinner.hide()
  }

  bindUpdateItem() {
    const {
      shoppingListId: shoppinglistId,
      pagination,
    } = this.state

    this.utils.updateQty('#shopping_list_table tbody tr', 'blur', 'update-list-item', async ($tr, target, e) => {
      e.preventDefault()
      e.stopPropagation()
      const {
        itemId,
      } = $tr.dataset

      const qty = $tr.querySelector('input.qty').value

      window.B3Spinner.show()
      try {
        await this.api.updateShoppingListItme({
          shoppinglistId,
          itemId,
          qty,
        })
        this.setState({
          pagination: {
            ...pagination,
            // offset: 0,
          },
        })
        await this.getShoppingListExtension()
        await this.utils.Alert.success(this.locales.tips.updateQtySuccess)
        this.render()
      } catch (err) {
        this.utils.Alert.error(this.locales.tips.globalError)
      }
      window.B3Spinner.hide()
    })
  }

  bindDeleteItem() {
    this.utils.on('#shopping_list_table tbody tr', 'click', 'data-delete-item', async ($tr, target, e) => {
      e.preventDefault()
      e.stopPropagation()
      const {
        itemId,
      } = $tr.dataset

      try {
        const {
          dismiss,
        } = await this.utils.Alert.warning(this.locales.tips.confirmDeleteShoppingListItem, {
          showCancelButton: true,
          confirmButtonText: this.text['global.alert.buttonText.delete'],
          confirmButtonColor: '#ff0000',
        })

        if (dismiss) return

        this.deletItem(itemId, true)
      } catch {
        this.utils.Alert.error(this.locales.tips.globalError)
      }
    })
  }

  filterEmptyFilesFromForm(formData) {
    for (let i = 0; i < formData.length; i += 1) {
      const [key, val] = formData[i]
      if (val instanceof File && !val.name && !val.size) {
        formData.delete(key)
      }
    }
    return formData
  }

  saveUpdate(e) {
    // update items
    const {
      editOptionsModal,
      isShowGrandTotal,
    } = this.state
    const $target = e.target
    const $modal = document.querySelector('#option-modal')
    const form = $modal.querySelector('form')

    const tr = $modal.querySelector('tr')
    const { shopingListItemId } = this.state
    $target.setAttribute('disabled', 'true')

    if (form) {
      const productId = tr.getAttribute('data-product-id')
      const checkAddStatus = this.checkIsAllRequiredOptionFilled(form, productId)
      if (!checkAddStatus) return
    }

    const optionList = this.getFormOptionList(form)

    const variantId = tr.getAttribute('data-variant-id')

    if (!variantId) {
      $target.removeAttribute('disabled')
      return this.utils.Alert.error(this.text['shopping.list.saveUpdate.errorMessage'])
    }

    window.B3Spinner.show()
    const data = {
      shoppinglistId: this.utils.urlHelper.searchParams.get('list_id'),
      itemId: shopingListItemId,
      optionList,
      variantId,
    }
    this.api.updateShoppingListItme(data).then(async () => {
      editOptionsModal.close()
      window.B3Spinner.hide()
      await this.utils.Alert.success(this.locales.tips.updateOptionsSuccess)
      await this.getShoppingListExtension()
      document.querySelector('#shopping_list_grand_total').innerHTML = +isShowGrandTotal ? this.text['shopping.list.item.grand.total'] + this.state.baseGrangTotal : ''
      this.renderShoppingList()
      const selectAll = document.querySelector('#select_all')
      selectAll.checked = false
    }).catch(() => {
      window.B3Spinner.hide()
    })
    $target.removeAttribute('disabled')
  }

  async deletItem(itemId, isLast) {
    const {
      shoppingListId,
      pagination,
    } = this.state

    window.B3Spinner.show()
    try {
      await this.api.deleteShopingListItme(shoppingListId, itemId)
      if (isLast) {
        window.B3Spinner.hide()
        await this.utils.Alert.success(this.locales.tips.deleteShoppingListItemSuccess)
        this.setState({
          pagination: {
            ...pagination,
            offset: 0,
          },
        })
        await this.getShoppingListExtension()
        this.render()
      }
    } catch {
      this.utils.Alert.error(this.locales.tips.globalError)
    }
    window.B3Spinner.hide()
  }

  bindCartEvents() {
    const $shoppingListTable = document.querySelector('#shopping_list_table')
    const $trs = $shoppingListTable.querySelectorAll('tr')
    const $toggle = document.querySelector('.bottom-cart-toggle')
    const $addToCart = document.querySelector('#add_to_cart')
    const $clearCart = document.querySelector('#clear_cart')
    const $bottomCart = document.querySelector('.bottom-cart-container')
    const $banner = document.querySelector('#consent-manager')
    const bannerHeight = $banner?.clientHeight
    const bottomCloseHeight = bannerHeight ? '-66px' : '-113px'
    const bottomOpenHeight = bannerHeight ? '48px' : '0'
    $bottomCart.style.bottom = bottomCloseHeight
    $toggle.addEventListener('click', () => {
      if ($banner) {
        const { isOpen } = this.state
        const bottomDistance = isOpen ? bottomCloseHeight : bottomOpenHeight
        $bottomCart.style.bottom = bottomDistance
        this.setState({
          isOpen: !isOpen,
        })
      } else {
        $bottomCart.classList.toggle('is-open')
      }
      $toggle.classList.toggle('is-open')
    })

    if ($addToCart) {
      $addToCart.addEventListener('click', async () => {
        const disabled = $addToCart.classList.contains('disabled')
        if (disabled) return

        const itemArr = []
        let allOptionsValid = true
        let invalidAdvQtyCount = 0
        const $checkedItems = document.querySelector('#shopping_list_table').querySelectorAll('.col-checkbox input[type=checkbox]:checked')
        $checkedItems.forEach($checkedItem => {
          const $tr = $checkedItem.parentNode.parentNode

          const notValidMin = $tr.querySelectorAll('.not-valid-min')
          const notValidInc = $tr.querySelectorAll('.not-valid-inc')
          const noOptionValue = $tr.querySelectorAll('[no-option-value]')

          if (notValidMin.length > 0 || notValidInc.length > 0) {
            invalidAdvQtyCount += 1
          }

          const {
            productId,
            variantId,
            productOptions,
          } = $tr.dataset

          const quantity = $tr.querySelector('[data-product-quantity] input').value
          const optionList = productOptions ? JSON.parse(productOptions) : []

          if (noOptionValue.length) {
            allOptionsValid = false
          }

          const originOptionList = []
          const dateSource = {}
          let isDateAssembleComplete = true

          optionList.forEach(option => {
            const {
              option_value,
              option_id,
            } = option
            // handle date modifier like: attribute[116][month]
            if (option_id.match(/\[([a-z]+)\]/g)?.[0]) {
              dateSource[option_id.match(/\[([a-z]+)\]/g)[0]] = option_value
              const year = dateSource['[year]']
              const month = dateSource['[month]']
              const day = dateSource['[day]']
              isDateAssembleComplete = year && month && day
              if (isDateAssembleComplete) {
                originOptionList.push({
                  option_id: `attribute${option_id.match(/\[([0-9]+)\]/g)[0]}`,
                  option_value: {
                    month,
                    day,
                    year,
                  },
                })
              }
            } else {
              originOptionList.push({ option_id, option_value })
            }
          })

          itemArr.push({
            productId,
            variantId,
            optionList: originOptionList,
            quantity,
          })
        })

        if (invalidAdvQtyCount > 0) {
          this.utils.Alert.error(this.locales.validation.shoppingListQtyValidError)
          return
        }

        if ($trs.length === 0) {
          this.utils.Alert.error(this.locales.validation.emptyShoppingList)
          return
        }

        if (itemArr.length === 0) {
          this.utils.Alert.error(this.locales.validation.emptySelectedShoppingList)
          return
        }

        if (!allOptionsValid) {
          this.utils.Alert.error(this.locales.validation.incompleteOptions)
          return
        }

        const optionSelectionsArr = itemArr.map(item => (
          item.optionList.map(option => ({
            optionId: +option.option_id.match(/\d+/g)[0],
            optionValue: option.option_value,
          }))
        ))

        const lineItems = itemArr.map((item, index) => ({
          quantity: item.quantity,
          productId: item.productId,
          // variantId: item.variantId, // version 1
          // FIX B3IAM-1063 Issues 4
          optionSelections: optionSelectionsArr[index].filter(option => +option.optionValue !== 0), // version 2
        }))

        window.B3Spinner.show()
        await this.api.addProducts({
          lineItems,
        })
        await this.utils.Alert.success(this.text['shopping.list.addProducts.successTips'])

        try {
          this.renderBottomCart()
        } catch {
          this.utils.Alert.error(this.locales.tips.globalError)
        }
        window.B3Spinner.hide()
      })
    }

    if (!$clearCart) return
    $clearCart.addEventListener('click', async () => {
      window.B3Spinner.show()
      try {
        // await this.api.clearCart() // have bug
        await this.deleteCarts()
        this.renderBottomCart()
      } catch {
        this.utils.Alert.error(this.locales.tips.globalError)
      }
      window.B3Spinner.hide()
    })
  }

  async setPricesInfo(product, $product, isSetQty, optionsList, qty, productIdItem) {
    const options = optionsList.map(option => ({
      ...option,
      optionId: option.option_id.replace(/[^0-9]/ig, ''),
      optionValue: option.option_value,

    }))

    const {
      currency: bcPriceCurrency,
      value: bcPriceValue,
    } = product?.price?.with_tax || product?.price?.without_tax || {}

    const {
      productId: b3ProductId,
      basePrice: b3BasePrice,
    } = productIdItem

    const {
      currency_selector: {
        active_currency_code: activeCurrencyCode,
      },
    } = this.context

    const $priceValue = $product.querySelector('[data-product-price-value]')

    if (isSetQty) {
      const variantId = +$product.getAttribute('data-variant-id')
      const { store_hash: storeHash } = this.context.settings
      const { customer_group_id: customerGroupId } = this.context.customer
      const {
        B3Storage: { B3CompanyId },
      } = this.utils
      const companyId = B3CompanyId.value ? B3CompanyId.value : null
      const channelId = window.jsContext?.channelId || 1

      const {
        productList,
      } = await this.api.getProductsBulkLoad({
        storeHash,
        currencyCode: bcPriceCurrency || activeCurrencyCode,
        companyId,
        productList: [
          {
            productId: +product.id || +b3ProductId,
            variantId,
          },
        ],
        customerGroupId,
        channelId,
      })

      const {
        modifiers = [],
        hasPriceList,
        bulkPrices = [],
        calculatedPrice: variantPrice,
        purchasingDisabled,
      } = productList.find(({ productId }) => productId === +product.id || productId === +b3ProductId) || {}

      if ((product.can_purchase !== undefined && product.can_purchase === false) || purchasingDisabled || !product.id) {
        const $checkbox = $product.querySelector('.col-checkbox > input') || {}
        $checkbox.disabled = true
        $checkbox.title = this.locales.tips.buyAgainFailedNoLongerForSale
      }

      const unitPrice = variantPrice || bcPriceValue

      product.options = this.getModifierAdjuster(options || [], modifiers)
      const calculatedPrice = this.getCalculatedPrice(qty, bulkPrices, unitPrice, hasPriceList, options)
      $priceValue.setAttribute('data-product-price-value', calculatedPrice)
      $priceValue.innerHTML = `<span class="product-price">${this.currencyCodeFormat({ code: bcPriceCurrency || activeCurrencyCode, value: calculatedPrice })}</span>`
      const productSubTotalValue = calculatedPrice * qty
      const productSubTotal = this.currencyCodeFormat({ code: bcPriceCurrency || activeCurrencyCode, value: productSubTotalValue })
      $product.querySelector('.product-subtotal').innerHTML = productSubTotal
    } else {
      $priceValue.setAttribute('data-product-price-value', bcPriceValue || b3BasePrice)
      $priceValue.innerHTML = `<span class="product-price">${this.currencyCodeFormat({ code: bcPriceCurrency || activeCurrencyCode, value: bcPriceValue || b3BasePrice })}</span>`
    }
    this.renderShoppingListCount()
  }

  bindSkuSearch() {
    const $skuSearch = document.querySelector('#search_skus')
    const $input = document.querySelector('#product_search_skus')
    const $resultContainer = document.querySelector('#skus_search_results')

    if ($skuSearch) {
      $skuSearch.addEventListener('click', async () => {
        const searchValue = $input.value
        const variantSku = searchValue.split(',').map(sku => (sku.replace(/^\s*|\s*$/g, '')))

        window.B3Spinner.show()
        try {
          const resp = await this.api.getProductsBySkuQuick({
            variantSku,
          })
          const productIds = []
          const products = resp.filter(({ modifiers }) => !modifiers.length)

          const purchasableProducts = products.filter(({ purchasingDisabled }) => !(purchasingDisabled * 1))

          const result = purchasableProducts.map((product, idx) => {
            const {
              option,
              productId,
              calculatedPrice,
            } = product
            const optionsList = option.map(({
              optionId,
              id,
            }) => ({
              option_id: `attribute[${optionId}]`,
              option_value: id,
            }))

            const imgId = `img_id_${productId}_${idx}`

            productIds.push({
              productId,
              itemId: productId,
              optionsList,
              imgId,
              basePrice: calculatedPrice,
            })
            return {
              ...product,
              optionsList: JSON.stringify(optionsList),
              idx,
            }
          })

          const resultContent = this.tpls.skuSearchResultTemplate({
            result,
            searchValue,
          })

          $resultContainer.innerHTML = resultContent
          this.setProductInfo(productIds)
        } catch {
          this.utils.Alert.error(this.locales.tips.globalError)
        }
        window.B3Spinner.hide()
      })
    }
    if ($input) {
      $input.addEventListener('keydown', async e => {
        if (e.keyCode === 13) {
          const searchValue = $input.value
          const variantSku = searchValue.split(',').map(sku => (sku.replace(/^\s*|\s*$/g, '')))

          window.B3Spinner.show()
          try {
            const resp = await this.api.getProductsBySkuQuick({
              variantSku,
            })

            const productIds = []
            const products = resp.filter(({ modifiers }) => !modifiers.length)

            const purchasableProducts = products.filter(({ purchasingDisabled }) => !(purchasingDisabled * 1))

            const result = purchasableProducts.map((product, idx) => {
              const {
                option,
                productId,
                calculatedPrice,
              } = product
              const optionsList = option.map(({
                optionId,
                id,
              }) => ({
                option_id: `attribute[${optionId}]`,
                option_value: id,
              }))

              const imgId = `img_id_${productId}_${idx}`

              productIds.push({
                productId,
                itemId: productId,
                optionsList,
                imgId,
                basePrice: calculatedPrice,
              })
              return {
                ...product,
                optionsList: JSON.stringify(optionsList),
                idx,
              }
            })

            const resultContent = this.tpls.skuSearchResultTemplate({
              result,
              searchValue,
            })

            $resultContainer.innerHTML = resultContent
            this.setProductInfo(productIds)
          } catch (error) {
            console.error(error)
            this.utils.Alert.error(this.locales.tips.globalError)
          }
          window.B3Spinner.hide()
        }
      })
    }
  }

  setProductInfo = async productIds => {
    try {
      const productIdsCollection = []
      for (let index = 0; index < productIds.length; index += 1) {
        const product = productIds[index]
        const getImage = new Promise((resolve, reject) => this.setImageAndPriceInfo(product, resolve, reject))
        productIdsCollection.push(getImage)
      }
      await Promise.allSettled(productIdsCollection)
    } catch (e) {
      console.error(e)
    }
  }

  async bindStatusChange() {
    const $updateStatus = document.querySelectorAll('[data-update-status]')
    const {
      B3RoleId,
    } = this.utils.B3Storage
    const {
      shoppingListId,
      status,
    } = this.state
    const {
      constants: {
        B3Role: {
          ADMIN,
          SENIOR,
          JUNIOR,
          SALESREP,
        },
      },
    } = this.utils
    const roleId = B3RoleId.value

    if ($updateStatus) {
      if ($updateStatus.length !== 0) {
        [0, 1].forEach(i => {
          $updateStatus[i].addEventListener('click', async () => {
            let selectedStatus = $updateStatus[i].getAttribute('name')
            if (selectedStatus === this.text['shopping.list.status.approved']) {
              selectedStatus = 0
            } else if (selectedStatus === this.text['shopping.list.status.draft']) {
              selectedStatus = 30
            }
            if (roleId === JUNIOR) {
              this.utils.Alert.error(this.locales.tips.noItemList)
              return
            }

            if (+status === +selectedStatus) return

            window.B3Spinner.show()
            try {
              await this.api.updateShoppingList({
                status: selectedStatus,
                id: shoppingListId,
              })
              await this.utils.Alert.success(this.locales.tips.updateShoppingListSuccess)
              if (
                (roleId === JUNIOR && +selectedStatus === 40)
                || ([ADMIN, SENIOR, SALESREP].includes(roleId) && +selectedStatus === 0)
              ) {
                window.location.reload()
              }
              if ([ADMIN, SENIOR, SALESREP].includes(roleId) && +selectedStatus === 30) {
                window.location.href = '/shopping-lists/'
              }
            } catch {
              this.utils.Alert.error(this.locales.tips.globalError)
            }
            window.B3Spinner.hide()
          })
        })
      }
    }
  }

  bindAddToShoppingList() {
    const {
      shoppingListId,
    } = this.state

    const $add = document.querySelector('#add_items_to_list')
    if ($add) {
      $add.addEventListener('click', async () => {
        const $resultTables = document.querySelectorAll('[product-search-result-table]')
        const $checkedProducts = []
        const $trs = []
        const seachInPuts = document.querySelectorAll('#product_search_results [product-search-result-table] [data-results-check-box]:checked')
        const searchSkusInputs = document.querySelectorAll('#skus_search_results [product-search-result-table] [data-results-check-box]:checked')
        let searchSkusArry = []

        $resultTables.forEach($resultTable => {
          const $checkeds = $resultTable.querySelectorAll('[data-results-check-box]:checked')
          const $tableTrs = $resultTable.querySelectorAll('tr')
          $checkedProducts.push(...$checkeds)
          $trs.push(...$tableTrs)
        })
        await this.checkInputNull()
        if (!$trs.length) {
          this.utils.Alert.error(this.locales.tips.searchProducts)
          return
        }
        if (!$checkedProducts.length) {
          this.utils.Alert.error(this.locales.tips.selectProducts)
          return
        }

        const checkNum = /^[1-9]\d*$/
        const addToShoppingListData = []
        const searchBynameProducts = [...seachInPuts]

        for (let i = 0; i < searchBynameProducts.length; i += 1) {
          const $checked = searchBynameProducts[i]
          const singleProductQuantity = $checked.parentNode.parentNode.querySelector('.product-qty-col .form-input').value

          if (!checkNum.test(singleProductQuantity)) {
            $checked.focus()
            this.utils.Alert.error(this.locales.tips.invalidQty)
            return
          }

          // check top search
          const form = $checked.parentNode.parentNode.querySelector('form')

          let checkAddStatus = true
          if (form) {
            const { productId } = $checked.parentNode.parentNode.dataset
            checkAddStatus = this.checkIsAllRequiredOptionFilled(form, productId)
          }

          if (!checkAddStatus) return

          if (checkAddStatus) {
            const $checkedTr = $checked.parentNode.parentNode

            const qty = $checkedTr.querySelector('.product-qty-col input').value
            const { productId } = $checkedTr.dataset
            let { variantId } = $checkedTr.dataset

            if (
              !productId
              || (
                !variantId
                && $checkedTr.querySelectorAll('[data-product-attribute]').length > 0
              )
            ) {
              this.utils.Alert.error(this.locales.validation.unValidProduct)
              return
            }

            if (!variantId) {
              variantId = productId
            }

            const optionList = this.getFormOptionList(form)

            const productItem = {
              id: shoppingListId,
              items: [
                {
                  productId,
                  variantId,
                  qty,
                  optionList,
                },
              ],
              checkAddStatus: true,
            }
            addToShoppingListData.push(productItem)
          }
        }

        if (searchSkusInputs) searchSkusArry = [...searchSkusInputs]

        searchSkusArry.forEach(item => {
          const $checkedTr = item.parentNode.parentNode
          const optionList = $checkedTr.getAttribute('data-product-options')
          const productId = $checkedTr.getAttribute('data-product-id')
          const variantId = $checkedTr.getAttribute('data-variant-id')
          const qty = $checkedTr.querySelector('.product-qty-col input').value

          addToShoppingListData.push({
            id: shoppingListId,
            items: [
              {
                productId,
                variantId,
                qty,
                optionList: JSON.parse(optionList),
              },
            ],
            checkAddStatus: true,
          })
        })

        addToShoppingListData.forEach((item, index) => {
          const last = (addToShoppingListData.length === index + 1)
          if (item.checkAddStatus) this.addToShoppingList(item, last)
        })
      })
    }
  }

  checkInputNull() {
    const $inputs = document.querySelector('#quick_add_section #product_search_results').querySelectorAll('[data-results-check-box]:checked')
    return new Promise((resolve, reject) => {
      for (let i = 0; i < $inputs.length; i += 1) {
        const $input = $inputs[i]
        const $qty = $input.parentNode.parentNode.querySelector('.product-qty-col .form-input')
        const val = $qty.value

        if (!val || val === '') {
          this.utils.Alert.error(this.locales.validation.emptyQty)
          reject()
          return
        }
      }
      resolve()
    })
  }

  checkRequireInputs(form) {
    let status = true
    const requireInputsArry = this.getRequireInputs(form)
    const formInputsArry = this.getFormInputs(form)
    for (let i = 0; i < requireInputsArry.length; i += 1) {
      const item = requireInputsArry[i]
      if (!formInputsArry[item]) {
        status = false
      }
    }
    return status
  }

  checkIsAllRequiredOptionFilled(form, productId) {
    const { productViewOptionsMap } = this.state
    if (!productViewOptionsMap[productId]) return true

    const optionList = this.getFormOptionList(form)
    const { options: bcOriginalOptions } = productViewOptionsMap[productId]

    return this.isAllRequiredOptionFilled(bcOriginalOptions, optionList)
  }

  getRequireInputs(form) {
    const $requireInputs = form.querySelectorAll('[required]')
    const attrArry = []
    if (!$requireInputs.length) return false

    $requireInputs.forEach($requireInput => {
      if ($requireInput.hasAttribute('required')) {
        const attr = $requireInput.getAttribute('name')
        attrArry.push(attr)
      }
    })

    return Array.from(new Set(attrArry))
  }

  getFormOptionList(form) {
    if (!form) return []

    const optionList = []
    const options = this.getFormInputs(form)
    Object.getOwnPropertyNames(options).forEach(key => {
      if (key.indexOf('attribute[') > -1) {
        optionList.push({
          option_id: key,
          option_value: options[key],
        })
      }
    })

    return optionList
  }

  getFormInputs(form) {
    const attributeArry = this.serialize(form)
    return attributeArry
  }

  serialize(form) {
    const arr = {}
    for (let i = 0; i < form.elements.length; i += 1) {
      const file = form.elements[i]
      switch (file.type) {
        case undefined:
        case 'button':
        case 'file':
        case 'reset':
        case 'submit':
          break
        case 'checkbox':
        case 'radio':
          if (!file.checked) {
            break
          } else {
            if (arr[file.name]) {
              arr[file.name] = `${arr[file.name]},${file.value}`
            } else {
              arr[file.name] = file.value
            }
            break
          }
        default:
          if (arr[file.name]) {
            arr[file.name] = `${arr[file.name]},${file.value}`
          } else {
            arr[file.name] = file.value
          }
      }
    }
    return arr
  }

  async addToShoppingList(item, last) {
    const {
      pagination,
    } = this.state

    window.B3Spinner.show()
    try {
      await this.api.addProductToShoppingList(item)
      if (last) {
        this.utils.Alert.success(this.locales.tips.addToShoppingListSuccess)
        this.setState({
          pagination: {
            ...pagination,
            offset: 0,
          },
        })
        await this.getShoppingListExtension()
        this.renderShoppingList()
      }
    } catch {
      // this.utils.Alert.error(this.locales.tips.globalError)
    }
    window.B3Spinner.hide()
  }

  setImageAndPriceInfo(originalProduct, resolve, reject) {
    const { productId, imgId, optionsList } = originalProduct
    this.stencilUtils.api.product.getById(productId, {
      template: 'b3/b3json',
    }, (err, response) => {
      if (err) {
        const $product = document.querySelector(`[data-item-sku-search-${productId}]`)
        this.setPricesInfo({}, $product, false, optionsList, 0, originalProduct)

        reject(err)
      }

      const {
        product,
      } = JSON.parse(response)

      document.querySelector(`[data-img-id=${imgId}]`).src = this.utils.getCorrectProductImage(product, 'main_image')?.['main_image']?.['data']
      document.querySelector(`[data-img-id=${imgId}]`).alt = this.utils.getCorrectProductImage(product, 'main_image')?.['main_image']?.['alt']
      const $product = document.querySelector(`[data-item-sku-search-${productId}]`)

      this.setPricesInfo(product, $product, false, optionsList, 0, originalProduct)

      resolve()
    })
  }

  renderShoppingListCount() {
    const {
      grandTotal,
      defaultCurrency,
      products,
      isShowGrandTotal,
    } = this.state
    const {
      currencyFormat,
    } = this.utils

    let productTotalQty = 0

    if (products.length > 0) {
      products.forEach(product => {
        productTotalQty += product.qty
      })
    }

    const $currentPageProductsCount = document.querySelector('#num_items')
    const $totalProductsCount = document.querySelector('#total_products_count')
    const $shoppingListGrandTotal = document.querySelector('#shopping_list_grand_total')
    $shoppingListGrandTotal.innerHTML = +isShowGrandTotal ? `${this.text['shopping.list.item.grand.total']}&nbsp;${currencyFormat(grandTotal, true, defaultCurrency)}` : ''
    $currentPageProductsCount.innerText = products.length
    $totalProductsCount.innerText = productTotalQty
  }

  async allowJuniorToCheckout() {
    if (this.isGetAllowJuniorPlaceOrders) {
      const {
        isEnabled,
      } = await this.api.getAllowJuniorPlaceOrders()

      this.utils.B3Storage.juniorCheckoutEnabled.setValue(isEnabled)

      this.setState({
        isAllowJuniorToCheckout: +isEnabled === 1,
      })
    } else {
      this.setState({
        isAllowJuniorToCheckout: this.isGetAllowJuniorPlaceOrders,
      })
    }
  }

  submitBtn() {
    const $toCheckoutBtn = document.querySelector(
      '.shoppinglist-checkout-btn-wrap input',
    )

    $toCheckoutBtn?.addEventListener('click', async () => {
      window.B3Spinner.show()
      await this.allowJuniorToCheckout()

      const {
        products,
        isAllowJuniorToCheckout,
      } = this.state

      if (!isAllowJuniorToCheckout) {
        window.B3Spinner.hide()
        this.utils.Alert.error(this.locales.tips.noPermission)
        return
      }

      const variantSkus = products.map(product => product.variantSku)

      let res
      let resp
      let cannotPurchase
      const itemArr = []
      const $trs = document.querySelectorAll('#shopping_list_table tbody tr')
      try {
        res = await this.api.getProductsBySkuQuickByPost({
          variantSkus,
        })

        res.forEach(product => {
          if (product.isVisible === '0' || product.purchasingDisabled === '1' || (product.isStock === '1' && product.stock === 0)) {
            cannotPurchase = true
          }
        })

        if (cannotPurchase) {
          window.B3Spinner.hide()
          this.utils.Alert.error(this.locales.tips.cannotPurchase)
          return
        }

        $trs?.forEach(item => {
          res.forEach(product => {
            if (product.variantId === +item.getAttribute('data-variant-id')) {
              let options
              const optionList = JSON.parse(item.getAttribute('data-product-options') || '[]')

              if (optionList.length > 0) {
                options = optionList.map(option => ({
                  optionId: +option.option_id.slice(10, -1),
                  optionValue: +option.option_value,
                }))
              }

              const productObj = {
                quantity: +item.querySelector('.qty').value,
                productId: +product.productId,
                variantId: product.variantId,
                optionSelections: options,
              }

              if (productObj.productId && productObj.quantity) {
                  itemArr.push(productObj)
              }
            }
          })
        })
        const lineItems = {
          lineItems: itemArr,
        }

        const cart = await this.api.getCart()

        if (Array.isArray(cart) && cart.length) await this.juniorDeleteCarts(cart[0].id)

        resp = await this.api.addProductsToCart('/api/storefront/carts', lineItems)

        if (resp?.data.id) {
          window.B3Spinner.hide()
          location.href = '/checkout'
        }
      } catch (error) {
        console.error(error)
      }
    })
  }
}
